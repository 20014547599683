<template>
    <div class="bg-light">
      <div class="container-fluid">
        <!-- Primera sección -->
        <div class="row py-5">
          <!-- Detalles del curso -->
          <div class="col-lg-4 col-md-12 pr-5 detailsCourse">
            <h3
              class="mb-4 font-weight-bold"
              :class="{
                loader: !info.name,
                'loader-titles': !info.name,
              }"
            >
              {{ info.name }}
            </h3>
  
            <ul class="pl-3 mb-5 list-unstyled">
              <!-- <li
                class="my-1"
                :class="{
                  loader: !mCourse.created_at,
                  'loader-text-small': !mCourse.created_at,
                }"
              >
                <i class="fas fa-calendar-alt mr-3"></i
                ><strong>Publicado:</strong>
                {{ mCourse.created_at }}
              </li> -->
              <!-- <li
                class="my-1"
                :class="{
                  loader: !mCourse.categoria,
                  'loader-text-small': !mCourse.categoria,
                }"
              >
                <i class="fas fa-bezier-curve mr-2"></i
                ><strong>Categoría:</strong>
                {{ mCourse.categoria }}
              </li> -->
              <li>
                <button
                  v-if="mode == 'private'"
                  class="btn mt-3"
                  style="
                    border-radius: 15px;
                    font-weight: 700;
                    background-color: #ffc107;
                  "
                  @click="redirectToPay()"
                >
                  Comprar
                </button>
                <button
                  v-if="mode == 'public'"
                  class="btn mt-3"
                  style="
                    border-radius: 15px;
                    font-weight: 700;
                    background-color: #ffc107;
                  "
                  @click="goToLogin()"
                >
                  Regístrate o inicia sesión
                </button>
              </li>
            </ul>
            <div>
              <button
                v-if="this.courseFilter == false"
                class="btn-custom"
                @click="BuyCourse()"
                style="
                  font-size: 18px;
                  color: black;
                  font-weight: 600;
                  line-height: 1.5rem;
                "
                :class="{ loader: !mCourse.nombre }"
              >
                <img
                  v-if="mCourse.precio == ''"
                  width="25"
                  :src="require('@/assets/free.png')"
                  alt=""
                />
                {{
                  mCourse.precio > 0
                    ? "Comprar ahora $" + mCourse.precio + ""
                    : "Inscribete ahora"
                }}
              </button>
              <div v-for="product in products" :key="product.id" class="text-left py-1">
                <button class="btn btn-primary" @click="redirectToProductInfo(product)">{{product.nombre}}</button>
              </div>
            </div>
          </div>
  
          <div class="col-lg-8 pr-0 pl-4">
            <WatchIntro :url="url"></WatchIntro>
          </div>
        </div>
  
        <!-- <div class="row">
          <div class="col-lg-9 col-md-12 mt-4">
            <div class="border-box">
              <v-expansion-panels accordion v-if="isDetailsLoading">
                <v-expansion-panel>
                  <v-expansion-panel-header style="font-weight: bold">
            
                    1. Acerca de este curso
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="text-justify">
                    {{ mCourse.descripcion }}
                  </v-expansion-panel-content>
                </v-expansion-panel>
  
                <v-expansion-panel>
                  <v-expansion-panel-header style="font-weight: bold">
             
                    2. Objetivos del Programa
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="text-justify">
                    {{ mCourse.objetivo }}
                  </v-expansion-panel-content>
                </v-expansion-panel>
  
                <v-expansion-panel>
                  <v-expansion-panel-header style="font-weight: bold">
                    3. ¿A quién va dirigido?
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="text-justify">
                    {{ mCourse.course_for }}
                  </v-expansion-panel-content>
                </v-expansion-panel>
  
                <v-expansion-panel>
                  <v-expansion-panel-header style="font-weight: bold">
                    4. Metodología de Estudio
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="text-justify">
                    {{ mCourse.will_learn }}
                  </v-expansion-panel-content>
                </v-expansion-panel>
  
          
              </v-expansion-panels>
  
              <v-skeleton-loader v-else type="sentences@5"></v-skeleton-loader>
            </div>
  
            <div class="mt-5">
              <span
                class="ml-5"
                style="
                  background-color: #f8f9fa;
                  font-weight: bold;
                  font-size: 1em;
                "
              >
                5. Plan de Estudio
              </span>
  
              <v-expansion-panels accordion v-if="isDetailsLoading" class="mt-5">
                <v-expansion-panel
                  v-for="(module, index) in course.modules"
                  :key="index + 1"
                >
                  <v-expansion-panel-header
                    style="font-weight: bold; background-color: #ffc700"
                  >
                    Módulo {{ index + 1 }}. {{ module.name }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="text-justify">
                    <ul class="list-unstyled">
                      <li
                        v-for="(less, subindex) in course.modules[index].lessons"
                        :key="subindex + 1"
                        class="my-4"
                      >
           
                        <div class="cursor-pointer" v-if="less.is_preview == 1">
                          <i
                            class="far fa-play-circle mr-3"
                            style="color: #ffc700; font-size: 1em"
                          ></i
                          >{{ index + 1 }}.{{ subindex + 1 }} {{ less.name }}
                        </div>
                        <div v-else>
                          <i class="fas fa-lock mr-3"></i>{{ index + 1 }}.{{
                            subindex + 1
                          }}
                          {{ less.name }}
                        </div>
                      </li>
                    </ul>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <v-skeleton-loader v-else type="sentences@5"></v-skeleton-loader>
            </div>
          </div>
  
          <div class="col-lg-3 col-md-12 mt-4 pr-0">
            <div class="mt-4">
              <h5
                class="font-weight-bold my-3"
                :class="{
                  loader: loadingRelated,
                  'loader-text-small': loadingRelated,
                }"
              >
                Recomendaciones
              </h5>
  
              <div v-if="loadingRelated">
                <div class="loader loader-card my-4"></div>
                <div class="loader loader-card my-4"></div>
                <div class="loader loader-card my-4"></div>
              </div>
              <div class="card-container">
                <div
                  class="mb-4 cursor-pointer"
                  v-for="course in all_courses.slice(0, 3)"
                  :key="course.id"
                >
                  <Card :course="course" :mode="mode" />
                </div>
              </div>
            </div>
          </div>
        </div> -->
  
        <!-- <div
          class="modal fade"
          id="video"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered modal-xl">
            <div class="modal-content bg-dark">
              <div class="modal-header">
                <h3
                  class="modal-title text-white text-center"
                  id="staticBackdropLabel"
                >
                  {{ mCourse.nombre }}
                </h3>
              </div>
              <div class="modal-body">
                <div class="video">
                  <WatchIntro v-if="renderVideo" />
                  <div v-else class="center-spinner">
                    <b-spinner
                      style="width: 3rem; height: 3rem"
                      variant="secondary"
                      label="Large Spinner"
                    ></b-spinner>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <!-- <b-modal id="bv-modal-example" hide-footer>
          <template #modal-title>
            {{modalTitle}}
          </template>
          <div class="d-block text-center">
            <h3>{{modalMessage}}</h3>
          </div>
          <b-button class="mt-3" block @click="$bvModal.hide('bv-modal-example')">Ir a mis cursos</b-button>
        </b-modal> -->
      </div>
      <!-- <div v-else>
        <p>xD</p>
      </div> -->
    </div>
  </template>
  
  <script>
  import WatchIntro from "@/components/auth/course/video/watch-intro";
  // import Card from "@/components/courses/cards";
  import { mapState, mapActions } from "vuex";
  import CulqiCheckout from "vue-culqi-checkout";
  
  export default {
    // props: {
    //   mode: {
    //     type: String,
    //     required: true,
    //   },
    // },
    name: "Product",
    data() {
      return {
        slug: "",
        url: "",
        mCourse: {},
        mode: "",
        all_courses: [],
        course_id: null,
        loadingRelated: true,
        isDetailsLoading: false,
        courseFilter: null,
        products: [],
        info: [],
        culqiConfig: {
          // publicKey: "pk_live_bb31092d20cc0fac",
          publicKey: "pk_test_cdbcffcc00366c42",
          title: "INSTITUTO PERUANO SSOMA",
          currency: "PEN",
          style: {
            logo: "https://www.ipssoma.com/images/s2.png",
            maincolor: "#ffbf00",
            buttontext: "#ffffff",
          },
        },
      };
    },
    components: {
      WatchIntro,
      // Card,
    },
    computed: {
      ...mapState("course", ["course", "renderVideo", "isLoading"]),
    },
  
    methods: {
      redirectToProductInfo(product){
        console.log(product)
        const params = {
          slug: product.slug,
          type: this.productType(product.tipo_producto_id)
        }
        console.log(params);
        this.$router.push({
          name: "curso",
          params: params
        })
      },
      goToLogin() {
        localStorage.removeItem("access_token");
        this.$router.push("/iniciar-sesion");
      },
      productType(id_type){
        return id_type == 1 ? "curso" : id_type == 2 ? "diplomado" : "producto";
      },
      redirectToPay(){
        // if (this.precio === 0) {
        //   const { ok } = await this.buyCourse(this.pao_id);
        //   if (!ok) return;
        //   this.$router.push({ name: "suscription-user" });
        // } else {
          // this.pao_id = 1;
          // this.$router.push("/buy/" + this.pao_id);
          this.$router.push("/"+this.slug+"/pay");
        // }
      },
      async pay() {
        const token = await this.$culqi.openCheckout();
  
        let url = "https://api.culqi.com/v2/charges";
        let config = {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer sk_test_IgMIsYRwitu2jb5m",
            // Authorization: "Bearer sk_live_fddfcb5d2c8ddbb0",
          },
        };
        let body = {
          amount: this.culqiConfig.amount,
          currency_code: "PEN",
          email: token.email,
          source_id: token.id,
        };
        // const {data} = await this.$axios.post(url, body, config);
  
        await this.$axios.post(url, body, config).then((request) => {
          console.log("ok")
          if (request.data.outcome.type == "venta_exitosa") {
            this.modalTitle = "Felicidades";
            this.modalMessage = "El pago se realizó con éxito";
            // this.$bvModal.show("bv-modal-example");
            const form = {
              user_id: localStorage.getItem("id_user"),
              product_id: this.mCourse.id,
              amount: this.culqiConfig.amount,
              reference_code: request.data.reference_code,
            };
            this.$axios.post("/payments/save-payment", form);
          } else {
            this.modalTitle = "Lo sentimos";
            this.modalMessage = "No se ha efectuado el pago";
            // this.$bvModal.show("bv-modal-example");
          }
        });
      },
  
      ...mapActions("course", {
        getCourse: "getCourse",
        getVideo: "getVideo",
        buyCourse: "buyCourse",
      }),
  
      // GoCourse() {
      //   this.$router.push("/suscription-user");
      // },
  
      // async getAttributes() {
      //   const { data } = await this.$axios.get(
      //     `course/details/${this.$route.params.slug}`
      //   );
      //   this.mCourse = data;
      //   this.culqiConfig.amount = data.precio.toFixed(2).replace(".", "");
      //   this.culqiConfig.description = data.nombre;
      //   this.isDetailsLoading = true;
      // },
  
      // async getCourses() {
      //   const { data } = await this.$axios.get(`course/list`);
      //   this.all_courses = data;
      //   this.loadingRelated = false;
      // },
      async getOfferInfo(){
        await this.$axios.get(`/public/offers/info?slug=${this.slug}`).then((response) => {
          this.products = response.data.products_offer;
          this.info = response.data.offer_info;
          this.loadingRelated = false;
          this.isDetailsLoading = true;
        });
      },
  
      // async getVideo() {
      //   const { data } = await this.$axios.get(
      //     `video/get-video-intro/${this.slug}`
      //   );
      //   this.url = data;
      // },
    },
    // Buscar curso por slug
    watch: {
      // $route: {
      //   handler: function () {
      //     this.slug = this.$route.params.slug;
      //     this.getCourses();
      //     // this.getAttributes();
      //     // this.getCourse(this.slug);
      //     // this.getVideo();
      //   },
      //   immediate: true,
      // },
      // slug() {
      //   this.getAttributes();
      // },
      // mCourse() {
      //   alert("hola");
      // },
    },
    created() {
      this.slug = this.$route.params.slug;
      this.getOfferInfo();
      this.mode = localStorage.getItem("mode");
      // this.getAttributes();
      // this.getCourse(this.slug);
      window.Vue.use(CulqiCheckout, this.culqiConfig);
      // this.getCourses();
    },
  };
  </script>
  
  <style scoped>
  /* .avatar-productor {
    width: 300px;
  } */
  
  .container-fluid {
    width: 97%;
    margin-inline: auto;
    padding-bottom: 50px;
    background-image: none !important;
  }
  /* 
  .title-course {
    text-transform: uppercase;
  }
  
  .img-course {
    width: 100%;
    max-width: 900px;
    max-height: 427px;
    border-radius: 25px;
  }
  
  .img-card {
    width: 100%;
    border-radius: 25px 25px 0px 0;
    height: 50%;
  }
  
  @media (max-width: 700px) and (min-width: 577px) {
    .img-card {
      height: 40%;
    }
  }
  
  .img-productor {
    width: 100% !important;
    height: 100% !important;
    max-width: 100px;
    max-height: 100px;
  }
  
  .list-group {
    padding-left: 0;
    border-radius: 25px;
  }
  
  .video {
    width: 100%;
    margin: auto;
    height: 600px;
  } */
  /* 
  .puntuacion {
    font-size: 1.04rem;
  }
  
  .detalles p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  } */
  
  @media (min-width: 1800px) {
    .container-fluid {
      width: 76%;
    }
  }
  /* 
  @media (max-width: 1500px) {
    .detalles {
      font-size: 10px;
    }
  } */
  
  /* @media (max-width: 1200px) {
    .img-productor {
      width: 70% !important;
      height: 80% !important;
    }
  } */
  
  @media (max-width: 991px) {
    /* .loader-img-course {
      display: none !important;
    }
  
    .img-course {
      display: none !important;
    } */
  
    .detailsCourse {
      display: flex;
      flex-direction: column;
      justify-content: center !important;
      text-align: center !important;
      padding-right: 1rem !important;
    }
  
    .detailsCourse p {
      text-align: center !important;
    }
  
    p {
      padding-right: 0 !important;
    }
  /* 
    .cardCursos {
      width: 40% !important;
      flex: none !important;
    } */
  
    .card-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  /* 
    .detalles {
      font-size: 20px;
    } */
  }
  /* 
  @media (max-width: 576px) {
    .img-productor {
      width: 155% !important;
      margin-left: 0 !important;
    }
  
    .productor {
      display: flex !important;
      flex-direction: row !important;
    }
  
    .productor .image {
      width: 20% !important;
    }
  
    .productor .detalles {
      width: 80% !important;
    }
  
    .cardCursos {
      width: 100% !important;
      flex: none !important;
    }
  } */
  
  /* Animaciones de carga para cada elemento */
  
  .loader-titles {
    height: 40px;
    width: 100%;
  }
  /* 
  .loader-descriptions {
    height: 50px;
  } */
  
  .loader-text-small {
    height: 30px;
    width: 70%;
  }
  
  .loader-card {
    height: 300px;
  }
  /* 
  .loader-temary {
    height: 800px;
  }
  
  .loader-img-productor {
    height: 100%;
    width: 100%;
  }
  
  .loader-img-course {
    height: 427px;
  } */
  </style>
  